import React from "react"
import Index from "@pages/mt4-web-browser.js";

const Mt4WebBrowserJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default Mt4WebBrowserJa
